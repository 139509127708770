<template>
    <div class="profil-offcanvas">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasprofile" aria-labelledby="offcanvasprofileLabel">
            
            <div ref="close_modal" data-bs-dismiss="offcanvas"></div>
            <div class="offcanvas-body acc-ofcanvass-body">
                <div class="offcanvas-header account-v-header">
                    <div class="user-account-head">
                        <a href="#">
                            <h2> Hi {{ userData?.userid }}</h2>
                            <p class="text-white f-14">{{ translatedLangData('you-are-in-the-blue-tier','You are in the blue tier')}}</p>
                        </a>
                    </div>
                    <!-- <button type="button" class="close-offcanvass-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                        <img loading="lazy" src="@/assets/images/close-btn.webp" alt="">
                    </button> -->
                </div>
                <ul class="acc-open-menu">
                    
                    <li>
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft">
                                <h6>{{ translatedLangData('setting','One Click Bet')}}</h6>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <div class="information-icon click-bet-info">
                                    <span v-if="clickBetCheck && getClickBetSelectedValue()" data-bs-toggle="modal"
                                        data-bs-target="#clickBetValue">
                                        <label class="clickBet-value-label click-bet-value-btn">{{ getClickBetSelectedValue() }}</label>
                                    </span>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" v-model="clickBetCheck" @change="betCheckChangeFunc" type="checkbox" id="checkbtnOneclick" ref="checkbtnOneclick">
                                        <label class="form-check-label" for="checkbtnOneclick">{{ translatedLangData('on-click-bet','')}}</label>
                                    </div>
                                </div>
                                <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal" data-bs-target="#clickBetValue"></a>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft">
                                    <h6>{{ translatedLangData('wallet-amount','Wallet Amount')}}</h6>
                                <p>{{ translatedLangData('inclusive-bonus','Inclusive bonus')}}</p>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <span class="profit-color"> {{ userData?.balance - (lockedEarnedData?.earnBonus || 0) }}</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft">
                                <h6>{{ translatedLangData('net-exposure','Net Exposure')}}</h6>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <span class="loss-color">{{ getPositiveExposure(userData?.exposure) }}</span>
                            </div>
                        </div>
                    </li>
                    <div class="earn-lock-bonus-sec">

                        <li class="weekly-loose-bonus">
                            <div class="bonus-info-head">
                                <div class="wallet-info-h-lft earn-bonus-h">
                                    <h6>{{ translatedLangData('weekly-reward','Weekly Reward')}}</h6>
                                    <span class="information-icon">
                                        <a href="#informationModal" data-bs-toggle="modal">
                                            <img src="@/assets/images/info-icon.svg" alt="">
                                        </a>
                                    </span>
                                </div>
                                <div class="wallet-info-h-rgt">
                                    <span class="loss-color"></span>
                                </div>
                            </div>
                        </li>

                        <li v-if="lockedEarnedData">
                            <div class="bonus-info-head">
                                <div class="wallet-info-h-lft earn-bonus-h">
                                    <h6>{{ translatedLangData('earn-bonus','Earn Bonus')}}</h6>
                                    <span class="information-icon">
                                        <a href="#informationModal" data-bs-toggle="modal" @click="getRules()"><img src="@/assets/images/info-icon.svg" alt=""></a>
                                    </span>
                                </div>
                                <div class="wallet-info-h-rgt">
                                    <span class="loss-color"> {{ lockedEarnedData.earnBonus }}</span>
                                </div>
                            </div>
                        </li>
                        <li v-if="lockedEarnedData">
                            <div class="bonus-info-head">
                                <div class="wallet-info-h-lft locked-bonus-h">
                                    <button type=button class="locked-bonus-btn"  @click="openBonusLogs()">
                                        <h6>{{ translatedLangData('locked-bonus','Locked Bonus')}}</h6>
                                    </button>
                                    <span class="information-icon">
                                        <a href="Javascript:void(0);" @click="openBonusLogs()"><img src="@/assets/images/info-icon.svg" alt=""></a>
                                    </span>
                                </div>
                                <div class="wallet-info-h-rgt">
                                    <span class="loss-color"> {{ lockedEarnedData.lockedBonus }}</span>
                                </div>
                            </div>
                        </li>
                    </div>
                    
<!-- 
                    <li v-if="siteSettings.business_type == 2" >
                        <div class="bonus-info-head justify-content-start">
                            <div class="menu-details-heading">
                                <h4>Bonus Information</h4>
                            </div>
                            <div class="menu-details-heading ps-2" @click="getRules()">
                                <h4>
                                    <span class="information-icon">
                                        <a href="#informationModal" data-bs-toggle="modal"><img src="@/assets/images/info-icon.svg" alt=""></a>
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </li> -->
                    
                    
                    <li v-if="lockedEarnedData" class="bonus-v-wrapper">
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft v-welcome-bonu">
                                <h6>{{ translatedLangData('welcome-bonus','Welcome Bonus')}}</h6>
                                <span class="information-icon">
                                    <a href="Javascript:void(0);"><img src="@/assets/images/info-icon.svg" alt=""></a>
                                </span>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <span class="loss-color"> {{ lockedEarnedData.welcomeBonus }}</span>
                            </div>
                        </div>
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft">
                                <h6>{{ translatedLangData('play-with-bonus','Play With Bonus')}}</h6>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <div class="menu-heading-con">
                                    <div class="form-check form-switch m-0 p-0 loss-color">
                                        <input class="form-check-input" type="checkbox" id="settingCheckDefaults" v-model="getWalletType" @change="updateWalletType">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <!-- <li>
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft">
                                <h6>Bonus</h6>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <span class="loss-color"> 500</span>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="bonus-info-head">
                            <div class="wallet-info-h-lft">
                                <h6>Available <br> Withdrawal</h6>
                            </div>
                            <div class="wallet-info-h-rgt">
                                <span class="loss-color"> 500</span>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="bonus-type-wrapper">
                            <a href="javascript:void(0);">
                                <div class="bonus-user-tabs">
                                    <span>AWAITING BONUS</span>
                                    <span class="loss-color">0</span>
                                </div>
                            </a>
                        </div>
                    </li> -->

                    <li @click="closeModal()" v-if="siteSettings?.business_type == 2"> 
                        <div class="user-deposit-and-withdrawal-btn">
                            <div class="deposit-v-btn">
                                <a href="Javascript:void(0);" @click.prevent="getWalletGetway()">{{ translatedLangData('deposit','Deposit')}}</a>
                            </div>
                            <div class="deposit-v-btn"> 
                                <a @click.prevent="getWalletGetway()" class="text-white">{{ translatedLangData('withdraw','Withdraw Now')}}</a>
                            </div>
                        </div>
                    </li>

                    <li v-if="siteSettings?.business_type == 2">
                        <div class="user-referal-rgt-tab">
                            <router-link to="/refer-earn" @click="closeModal()">
                                <div class="user-tabs">
                                    <span>{{ translatedLangData('refer-and-earn','Refer and Earn')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>


                    <!-- <li>
                        <div class="user-rgt-tab">
                            <router-link to="/profile" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/profile.webp" alt="">
                                    <span>{{ translatedLangData('profile','Profile')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li> -->


                    <!-- <li>
                        <div class="user-rgt-tab">
                            <router-link to="/refer-earn" @click="closeModal()">
                                <div class="user-tabs">
                                    <img loading="lazy" src="@/assets/images/megaphone.webp" alt="">
                                    <span>Refer and Earn</span>
                                </div>
                            </router-link>
                        </div>
                    </li> -->

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/profit-loss" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/rupi.webp" alt="">
                                    <span>{{ translatedLangData('betting-p-l','Betting P&L')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <a href="Javascript:void(0);" @click.prevent="getWalletGetway(), closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/transactions.webp" alt="">
                                    <span>{{ translatedLangData('my-transactions','My Transactions')}}</span>
                                </div>
                            </a>
                        </div>
                    </li>

                    <li class="mt-2" v-if="siteSettings?.business_type == 2">
                        <div class="user-rgt-tab">
                            <a href="Javascript:void(0);" @click="closeModal(), getWalletGetway()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/user-wallet-icon.webp" alt="">
                                    <span>{{ translatedLangData('my-wallet','My Wallet')}}</span>
                                </div>
                            </a>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/reset-password" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/reset_password.webp" alt="">
                                    <span>{{ translatedLangData('reset-password','Reset Password')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/bets" @click="closeModal()">
                                <div class="user-tabs user-open-bet-count">
                                    <div class="">
                                        <img src="@/assets/images/open_bets.webp" alt="">
                                        <span>{{ translatedLangData('open-bets','Open Bets')}}</span>
                                    </div>
                                    <div class="">
                                        <span class="open-bet-count">{{ stateUser?.bets_count }}</span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/favourites" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/favorites.webp" alt="">
                                    <span>{{ translatedLangData('favourite','Favorites')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/notifications" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/notification.webp" alt="">
                                    <span>{{ translatedLangData('notification','Notification')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/account-statements" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/statements-icon.svg" alt="">
                                    <span>{{ translatedLangData('statements', 'Statements')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    

                    <!-- <li>
                        <div class="user-rgt-tab">
                            <router-link to="/change-mobile-no" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/change_no_white.webp" alt="">
                                    <span> {{ translatedLangData('change-mobile-no','Change Mobile No')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li> -->

                   
                    
                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/settled-bets" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/settled-icon.svg" alt="">
                                    <span>{{ translatedLangData('settled-bets','Settled Bets')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <!-- <li>
                        <div class="user-rgt-tab">
                            <router-link to="/cricket-fight" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/fantasy-crci-icon.webp" alt="">
                                    <span>{{ translatedLangData('criket-fight','Cricket Fight')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li> -->

                    

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/rules-regulations" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/rules.webp" alt="">
                                    <span>{{ translatedLangData('rule-regulations','Rules & Regulations')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <router-link to="/stake-settings" @click="closeModal()">
                                <div class="user-tabs">
                                    <img src="@/assets/images/settings.webp" alt="">
                                    <span>{{ translatedLangData('stake-settings','Stake Settings')}}</span>
                                </div>
                            </router-link>
                        </div>
                    </li>

                    <li>
                        <div class="user-rgt-tab">
                            <a href="#feedbackModal" @click="closeModal()" data-bs-toggle="modal">
                                <div class="user-tabs">
                                    <img src="@/assets/images/comment (1).webp" alt="">
                                    <span>{{ translatedLangData('feedback','Feedback')}}</span>
                                </div>
                            </a>
                        </div>
                    </li>

                    <!-- <li>
                        <div class="user-rgt-tab">
                            <a href="#" data-bs-toggle="modal">
                                <div class="user-tabs">
                                    <img loading="lazy" src="@/assets/images/chat.webp" alt="">
                                    <span>Chat With Us</span>
                                </div>
                            </a>
                        </div>
                    </li> -->

                    <!-- <li>
                        <div class="user-rgt-tab">
                            <a href="#language_selection_pop_up" data-bs-toggle="modal">
                                <div class="user-tabs">
                                    <img src="@/assets/images/world.webp" alt="">
                                    <span>{{ translatedLangData('language','Language')}}</span>
                                </div>
                            </a>
                        </div>
                    </li> -->
<!-- 
                    <li>
                        <div class="user-rgt-tab1" v-if="siteSettings && siteSettings?.business_type == 2">
                            <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank" 
                                v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1">
                                <div class="markets-and-news-sec fav whatsapp-connection">
                                    <span>{{ translatedLangData('connect-with-us-on','Connect with us on')}}</span>
                                    <button type="button">
                                        <i class="fa-brands fa-whatsapp"></i>
                                        <span>{{ translatedLangData('whatsapp','whatsapp')}}</span>
                                    </button>
                                </div>
                            </a>
                        </div>
                    </li> -->
                    <template v-if="siteSettings?.social_link?.whatsappno1">
                        <li>
                            <div class="user-rgt-tab1">
                                <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank" >
                                    <div class="markets-and-news-sec fav whatsapp-connection">
                                        <span>{{ translatedLangData('connect-with-us-on','Connect with us on')}}</span>
                                        <button type="button">
                                            <img src="@/assets/images/whatsapp.png" alt="">
                                            <span>{{ translatedLangData('whatsapp','whatsapp')}}</span>
                                        </button>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </template>
                    <li>
                        <div class="user-rgt-tab">
                            <div class="online-betting-app-btn">
                                <a data-bs-toggle="modal" data-bs-target="#logoutmodal" class="markets-and-news-sec fav-setting">
                                    <div class="user-tabs-setting">
                                        <span>{{ translatedLangData('logout','Logout')}}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>

        </div>
    </div>

    <LanguageSelection />
    <FeedBackModal @success-modal="showSuccessModalFunc"  @error-modal="showErrorModalFunc" @close="closeModal()"/>
    <ClickBetModal @success-modal="showSuccessModalFunc" @close-modal="closeClickBetModal" @error-modal="showErrorModalFunc" />
    <BonusInfoModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" :siteRuleData="siteRuleData"/>
    <LockedBonus v-if="showBonusLogs" @success-modal="showSuccessModalFunc"  @error-modal="showErrorModalFunc" @closeBonusLogs="closeBonusLogs()"></LockedBonus>

    <LogoutModal @close="closeModal()" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc"/>

</template>
<script>

import LanguageSelection from '@/shared/components/header/LanguageSelection.vue';
import LogoutModal from './LogoutModal.vue'; 
import FeedBackModal from './FeedBackModal.vue'; 
import ClickBetModal from './ClickBetModal.vue';
import { kFormatter } from '@/shared/utils/formatter';
import { mapGetters } from 'vuex';
import api from '../services/api'
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";
import LockedBonus from './LockedBonus.vue';
import BonusInfoModal from './BonusInfoModal.vue';

export default {
    name: 'Account',
    inject:['translatedLangData'],
    data() {
        return {
            walletData: null,
            lockedEarnedData: null,
            showBonusLogs: false,
            siteRuleData: null,
            getWalletType: false
        }
    },
    mounted() {
        if(this.checkIsLogin()){
            this.getWalletLimitsServiceCall()
        }
        if(this.checkIsLogin() && this.siteSettings.business_type == 2) {
            this.getLockedEarnedBonus();
            this.getWalletType = this.stateUser?.activeWallet == 'WB' ?? false;
        }
    },
    methods: {
        getPositiveExposure(exposure) {
        return Math.abs(exposure || 0);
        },
        closeClickBetModal(clickBetCheckValue) {
            if(!this.clickBetCheck) {
                this.$refs.checkbtnOneclick.checked = false;
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        closeModal() {
            this.$refs.close_modal.click();
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        getWalletLimitsServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            this.loading = true;
            api.get(apiName.WALLET_LIMITS, {headers}).then(response => {
                this.loading = false;
                this.walletData = null;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response?.data?.debug[0]);
                        }
                        else {
                            //this.showSuccessModalFunc(response.data.message);
                            this.walletData = response?.data?.data;
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getRules() {
            this.$store.dispatch('setGlobalLoader', true);
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                            // this.$emit('rulesModal');
                            // this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        clickOnWalletWithdraw() {
            if (this.walletData?.withdraw_limit >= 10) {
                this.$router.push({ path: '/wallet-withdraw', query: { walletData: JSON.stringify(this.walletData) } })
            }
            else {
                this.showErrorModalFunc("Insufficient balance")
            }
        },
        getClickBetSelectedValue() {
            if (this.getClickBetValue()) {
                let inputValues = JSON.parse(JSON.stringify([...this.getClickBetValue()]));
                if(this.$store.getters.clickBetActive) {
                    if (inputValues)
                        return inputValues.filter(item => item.isSelected)[0].value;
                }
            }
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        betCheckChangeFunc() {
            if (!this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setActiveClickBet', false)
            }
        },
        getWalletGetway() {
            if(this.siteSettings.business_type == 2){
                this.$router.push({name: 'wallet-gateway'});
            }
        },
        getLockedEarnedBonus() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_LOCKED_EARNED_BONUS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.lockedEarnedData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        openBonusLogs() {
            this.showBonusLogs = true;
        },
        closeBonusLogs() {
            setTimeout(() => {
                this.showBonusLogs = false;
            }, 400)
        },
        updateWalletType() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.post(apiName.UPDATE_WALLET_TYPE, {} , {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                            this.getWalletType = !this.getWalletType
                        }
                        else {
                            let user = this.stateUser;
                            user.activeWallet = response.data.data.active_wallet;
                            this.$store.dispatch('setUser', user);
                            this.$emit('success-modal', 'Successfully changed');
                            setTimeout(() => {
                                this.$emit('updateAuthToken', true);
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.getWalletType = !this.getWalletType;
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        }
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
		...mapGetters([
			'stateUser'
		]),
        userData () {
            return this.$store.getters.stateUser;
        },
        clickBetCheck() {
            return this.$store.getters.getactiveClickbet;
        }
    },
    components: {
    LanguageSelection,
    LogoutModal,
    ClickBetModal,
    FeedBackModal,
    LockedBonus,
    BonusInfoModal
}
}
</script>
<style scoped>
	.clickBet-value-label {
		position: relative;
		left: 4.5rem !important;
		font-weight: bold;
		color: #fff;
	}
</style>