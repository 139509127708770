<template>
    <div class="side-bar-toggle-btn">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">

            <div class="offcanvas-body">

                <div :class="['offcanvas-header', 'profil-head', { 'after-login-profile-h': checkIsLogin() }]">
                    <div class="join-btn-offcanvas-sec">

                        <div v-if="checkIsLogin()" class="join-now-btn v-avatar">
                            <div class="v-avatar-img">
                                <span>{{ userData?.userid[0].toUpperCase() }}</span>
                            </div>
                            <div class="text-truncate ms-2">
                                Hi, {{ userData?.userid }}
                            </div>
                        </div>

                        <div v-else class="join-now-btn">
                            <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo" class="web-logo" /><br/>
                            <router-link to="/sign-up" @click="closeModal()" class="cmn-btn123">{{ translatedLangData('register','Register')}}</router-link>
                        </div>

                        <!-- <div class="select-language-model-pop-up">
                            <a href="#language_selection_pop_up" data-bs-toggle="modal">Select laguage<i
                                    class="fa-solid fa-angle-down"></i></a>
                        </div> -->
                        
                    </div>
                    <div style="display: none;" ref="close_modal" @click="closeLangModal()"  data-bs-dismiss="offcanvas"
                        aria-label="Close"></div>
                </div>

                <div class="market">
                    <div class="row">
                        <div class="col-12">
                            
                            <router-link to="/markets" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/market.webp" alt="">
                                        <span>{{ translatedLangData('market','Market')}}</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <!-- <div class="col-12">
                            <router-link to="/static/NEWS" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/news.webp" alt="">
                                        <span>News</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div> -->
                        <div class="col-12">
                            <router-link to="/static/ABOUT_US" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/about-us-icons.webp" alt="">
                                        <span>{{ translatedLangData('about-us','About Us')}}</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <!-- <div class="col-12">
                            <router-link to="/static/TUTORIALS" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/help.webp" alt="">
                                        <span>Tutorials</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div> -->
                        <div class="col-12">
                            <router-link to="/static/PRIVACY_POLICY" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/privacy-policy.webp" alt="">
                                        <span>{{ translatedLangData('privacy-policy','Privacy Policy')}}</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div class="col-12">
                            <router-link to="/faqs" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/faqs.webp" alt="">
                                        <span>{{ translatedLangData('faq','FAQ')}}</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <!-- <div class="col-12">
                            <router-link to="/static/PROMOTIONS" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/promotions.webp" alt="">
                                        <span>{{ translatedLangData('promotions','Promotions')}}</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div> -->

                        <div class="col-12">
                            <router-link to="/static/TERM_CONDITIONS" @click="closeModal()">
                                <div class="markets-and-news-sec">
                                    <div class="market-title">
                                        <img loading="lazy" src="@/assets/images/terms-and-conditions.webp" alt="">
                                        <span>{{ translatedLangData('select','Select')}}</span>
                                    </div>
                                    <div class="next-arrow-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>

                    <!-- <div class="col-12">
                        <router-link to="/affiliate" @click="closeModal()">
                            <div class="markets-and-news-sec">
                                <div class="market-title">
                                    <img loading="lazy" src="@/assets/images/affiliate.webp" alt="">
                                    <span>Affiliate</span>
                                </div>
                                <div class="next-arrow-icon">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </router-link>
                    </div> -->
                    
                </div>

                <div class="col-12">
                    <button type="button" class="add-more-language-modal" data-bs-target="#changeLangModal" data-bs-toggle="modal"> 
                        <div class="markets-and-news-sec">
                            <div class="market-title">
                                <!-- <span>{{ $store.getters.languageSelected.lang_name}}</span> -->
                                <span>{{ selectLanguageData }}</span>
                            </div>
                            <div class="next-arrow-icon">
                                <i class="fa-solid fa-angle-down text-white"></i>
                            </div>
                        </div>
                    </button>
                </div>

                <div class="online-betting-app-btn">
                    <a href="#install-apk-model" data-bs-toggle="modal">
                        <div class="down-app-btn">
                            <span>{{ translatedLangData('download-the-app','Download App')}}</span>
                        </div>
                    </a>
                </div>

                <div class="remove-padding" v-if="checkIsLogin()">
                    <div class="row">
                        <div class=""  v-if="siteSettings?.social_link">
                            <div class="socail-link">
                                <ul>
                                    <li v-if="siteSettings.social_link.facebook_link"><a :href="siteSettings.social_link.facebook_link" target="_blank"><img loading="lazy" src="@/assets/images/facebook.webp" alt=""></a></li>
                                    <!-- <li><a href="#"><img loading="lazy" src="@/assets/images/twitter.webp" alt=""></a></li> -->
                                    <li v-if="siteSettings.social_link.telegram_link"><a :href="siteSettings.social_link.telegram_link" target="_blank"><img loading="lazy" src="@/assets/images/telegram.webp" alt=""></a></li>
                                    <li v-if="siteSettings.social_link.instagram_link"><a :href="siteSettings.social_link.instagram_link" target="_blank"><img loading="lazy" src="@/assets/images/instagram.webp" alt=""></a></li>
                                    <li v-if="siteSettings.social_link.youtube_link"><a :href="siteSettings.social_link.youtube_link" target="_blank"><img loading="lazy" src="@/assets/images/youtube.webp" alt=""></a></li>
                                    <!-- <li v-if="siteSettings.social_link.email"><a :href="'mailto:'+ siteSettings.social_link.email" target="_blank"><img loading="lazy" src="@/assets/images/email.webp" alt="" style="border-radius:0"></a></li> -->
                                    <li v-if="siteSettings && siteSettings?.business_type == 2">
                                        <a :href="'//wa.me/'+siteSettings?.social_link?.whatsappno1" target="_blank" 
                                            v-if="siteSettings?.social_link?.whatsappno1">
                                            <img loading="lazy" src="@/assets/images/whatsapp.webp" alt="">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="select-language-modal">
        <div class="modal fade" id="changeLangModal" tabindex="-1"  aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header p-0">
                    <div class="v-card__title">
                        {{ translatedLangData('select-language','Select Language')}}
                    </div>
                </div>
                <div class="modal-body p-0">
                    <div class="v-card__text justify-content-start">
                        <ul class="v-list-item__content">
                                <li v-for="item in filteredLanguages" :key="item.lang_code"  data-bs-dismiss="modal"
                                    :class="{ 'active': selectedValue?.lang_code == item?.lang_code }">
                                    <a  @click="selectedlanguage(item)"> {{ item.lang_name }}</a>
                                </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer change-lang-footer">
                    <button type="button" class="" @click="closeLangModal()" data-bs-dismiss="modal">{{ translatedLangData('close','Close')}}</button>
                </div>
                </div>
            </div>
        </div>
    </div>

    <DownloadApkModal />

</template>

<script>
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
import LanguageService from '@/shared/services/language-service.js'
import { mapGetters } from 'vuex';
export default {
    name: 'SideMenu',
    inject:['translatedLangData'],
    components: {
        DownloadApkModal
    },
    data() {
            return {
                userData: null,
                selectedValue: '',
            }
        },
        created() {
            this.userData = this.$store?.getters?.stateUser;
            if(this.$store.getters.languageSelected == null){
                this.$store.dispatch('setLanguageSelected', {lang_code: "en"} );
            }
            this.selectedValue=this.$store.getters.languageSelected            
        },
        computed: {
         ...mapGetters({
            siteSettings: 'siteSettings' , stateUser : 'stateUser',
        }),
        languagesData() {
            return this.$store.getters.languagesData;
        },
        selectLanguageData()
        {
            const selected_language_get = this.$store?.getters?.languagesData?.filter((data)=>data.lang_code == this.$store.getters.languageSelected.lang_code)
            return selected_language_get[0]?.lang_name
        },
        filteredLanguages() {
            if(this.searchTerm) {
                const searchTermLower = this.searchTerm.toLowerCase();
                return this.languagesData.filter(item => item.lang_name.toLowerCase().includes(searchTermLower));
            }
            return this.languagesData
        },
      },
    methods:{
        checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
        closeModal(){
            this.$refs.close_modal.click();
        },

        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeLangModal(){
            this.selectedValue=this.$store.getters.languageSelected
        },
        async selectedlanguage(item) {
            this.selectedValue = item
            if (this.selectedValue && this.selectedValue.lang_code === 'en') {
            localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_code);
            this.$store.dispatch('setLanguageSelected',  this.selectedValue);
            this.$parent.langData = "";
            this.showSuccessModalFunc('Language Updated Successfully');
            this.$refs.close_modal.click();
            document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
        } else if (this.selectedValue) {
            const aws_url_language = this.siteSettings?.aws_url + '/' + this.selectedValue?.lang_url;
            this.$parent.langData = await LanguageService.getLanguageTranslationKey(aws_url_language);
            if (this.$parent.langData) {
                localStorage.setItem('selectedLanguageUrl', this.selectedValue.lang_url);
                this.$store.dispatch('setLanguageSelected', this.selectedValue);
                this.showSuccessModalFunc('Language Updated Successfully');
                this.$refs.close_modal.click();
                document.querySelector('.btn.thm-bor-but[data-bs-dismiss="modal"]').click();
            } else {
                localStorage.removeItem('selectedLanguageUrl');
                this.$refs.close_modal.click();
                this.showErrorModalFunc('language Data Is Not Available');
            }
        }
        },
    }
}
</script>