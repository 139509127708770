const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames:null,
    globalLoader:false,
    depositAmount: 0,
    captchaData: null,
    sportsCount:null,
    openBets:null,
    languagesData:null,
    siteVersion:null,
    underMaintenance:null,
    activeClickbet: false,
    languageSelected:null

};
const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    getactiveClickbet: state => state?.clickBetValue?.find(item => item.isSelected) ? state?.activeClickbet : false,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    depositAmount:state => state?.depositAmount,
    captchaData: state => state?.captchaData,
    sportsCount: state => state?.sportsCount,
    openBets: state => state?.openBets,
    siteVersion: state => state?.siteVersion,
    underMaintenance: state => state?.underMaintenance,
    languageSelected: state => state?.languageSelected,

};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setActiveClickBet({ commit }, activeClickbet) {
        commit('setActiveClickBet', activeClickbet);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
    SetSportsCount({ commit }, sportsCount) {
        commit('SetSportsCount', sportsCount);
    },
    SetOpenBets({ commit }, openbets) {
        commit('SetOpenBets', openbets);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);

    },
    setLanguageSelected({ commit }, languageSelected) {
        commit('setLanguageSelected', languageSelected);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setActiveClickBet(state, activeClickbet) {
        state.activeClickbet = activeClickbet;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    },
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
    SetSportsCount(state, sportsCount) {
        state.sportsCount = sportsCount;
    },
    SetOpenBets(state, openbets) {
        state.openBets = openbets;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setLanguageSelected(state, languageSelected) {
        state.languageSelected = languageSelected;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};